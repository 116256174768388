.layout_paper {
  height: 100vh;
}
.layout_header {
  background-color: #1890ff;
  max-height: 50px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.layout_logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 8px 15px 8px 0;
  float: left;
  content: "后台管理";
}
.layout_sider {
  flex: 0 0 65px !important;
  max-width: 65px !important;
  min-width: 65px !important;
  width: 65px !important;

  .layout_sider_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .layout_sider_menu {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
// .layout_logo_mini {
//   color: white;
//   padding: 0;
// }
// .layout_header {
//   background-color: #fff;
//   padding: 0;
//   color: black;
// }
// .layout_footer {
//   text-align: center;
// }
// .layout_content {
//   margin: 0 16px;
// }

// .layout_breadcrumb {
//   margin: 16px 0;
// }

.main_content {
  background: transparent;
  padding: 0px;
  margin: 0;
  min-height: 280px;
  // overflow-y: auto;
}

