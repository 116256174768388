.blue_button_active {
  margin: 0;
  box-sizing: border-box;
  background: #1890ff !important;
  height: 4px !important;
  width: 36px;
  display: block;
  padding: 0;
  // color: transparent;
  font-size: 0;
  border: 0;
  border-radius: 3px;
  // outline: none;
  cursor: pointer;
  // transition: all 0.5s;
  // opacity: 1;
}

.blue_button {
  margin: 0;
  box-sizing: border-box;
  background: #a7a7a7 !important;
  height: 4px !important;
  width: 36px;
  display: block;
  padding: 0;
  // color: transparent;
  font-size: 0;
  border: 0;
  border-radius: 3px;
  // outline: none;
  cursor: pointer;
  // transition: all 0.5s;
  // opacity: 1;
}

