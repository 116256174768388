.appPanel_layout_header {
  border-bottom: 1px solid rgb(235, 237, 240);
}

.appPanel_layout_container {
  background-color: #f5f5f5;
  // border-bottom: 1px solid rgb(235, 237, 240);
}
.appPanel_list_container {
  display: flex;
  flex-wrap: wrap;
  padding: 24px;
  border-bottom: 1px solid rgb(235, 237, 240);
}

